window.$ = window.jQuery = require('jquery')
window._ = require('lodash')

window.App = {};
import tippy from "tippy.js";
window.App.$tippy = tippy;

// Try to delete this after webpack is gone. It prevents an error that occurs when making a JS function `async`.
// I specifically got it when I made an event handler `async`.
// NOTE: If you delete this, you should try to delete it in other JS entrypoints, too.
import "regenerator-runtime/runtime";

// Plug in Stimulus.
import "./controllers";

function serializeFormData(formID) {
  const obj = {}
  const arr = $(formID).serializeArray()
  arr.forEach(function (el) { obj[el.name] = el.value })
  return obj
}

// NOTE: This will need to be replaced with a listener for `turbo:load` if Turbo is added to portal.
$(document).ready(function () {
  $(document).on('click', '.c-case-status__back-button', function (e) {
    e.preventDefault()
    window.location = $(this).attr('href')
  })

  $(document).on('click', '.c-case-status__logout-button', function (e) {
    e.preventDefault()
    window.location = $(this).parent().attr('href')
  })

  $(document).on('click', '.c-case-status__victims-rights-link', function (e) {
    e.preventDefault()
    window.open($(this).attr('href'), "_blank")
  })

  $('.c-case-status__report-type-dropdown').dropdown()
  $('.c-case-status__victim-relationship-type-dropdown').dropdown()
  const en_placeholder = $('.c-case-status__victim-relationship-type-dropdown .text[data-language="en"]').html()
  const es_placeholder = $('.c-case-status__victim-relationship-type-dropdown .text[data-language="es"]').html()

  $(document).on('submit', '#verify-case-number, #verify-victim-info', function (e) {
    e.preventDefault()
    const formObj = serializeFormData(this)
    var victimInfo = _.omitBy(_.clone(formObj), _.isEmpty)
    delete victimInfo.utf8
    delete victimInfo.authenticity_token
    delete victimInfo.userAgent
    delete victimInfo.date_of_birth

    if (e.target.id == 'verify-case-number') {
      // Nothing.
    } else if (e.target.id == 'verify-victim-info') {
      if ($('#cold_case').prop('checked')) {
        if ($('.c-case-status__victim-relationship-type-dropdown > INPUT').val() == '') {
          $('.c-case-status__cold-case-form .c-case-status__field').addClass('error')
          $('.c-case-status__cold-case-dropdown-error').removeClass('hidden')
          return
        }
      }
    }

    $.post($(this).attr('action'), formObj).done(
      function (data) {
        window.location.href = data.redirect_url
      }
    ).fail(
      function () {
        $('.c-case-status__field').addClass('error')
        $('.c-case-status__error').removeClass('hidden')
      }
    )
  })

  if (!sessionStorage.getItem('language')) {
    sessionStorage.setItem('language', 'en')
  }

  if (sessionStorage.getItem('language') == 'en'){
    $('[data-language="es"]').hide()
  } else if (sessionStorage.getItem('language') == 'es') {
    $('[data-language="en"]').hide()
  }

  $(document).on('click', '.c-case-status__change-language', function () {
    var current_language = sessionStorage.getItem('language')
    var selected_language = $(this).data('selected-language')
    if (selected_language == 'es') {
      $('[data-language="en"]').hide()
      $('[data-language="es"]').show()
      $('INPUT')[2].focus()
      sessionStorage.setItem('language', 'es');
    } else if (selected_language == 'en') {
      $('[data-language="es"]').hide()
      $('[data-language="en"]').show()
      $('INPUT')[2].focus()
      sessionStorage.setItem('language', 'en')
    }

    var language = {
      en: "English",
      es: "Spanish"
    }

  })

  $(document).on('change', '.c-case-status__report-type-dropdown', function () {
    var report_type = $('.c-case-status__report-type-dropdown > INPUT').val()
    var cold_case = $('#cold_case').prop('checked')
    $('.c-case-status__form').trigger('reset')
    $('#cold_case').prop('checked', cold_case)
    $('.c-case-status__field').removeClass('error')
    $('.c-case-status__error').addClass('hidden')
    $('.c-case-status__form INPUT').not('.c-case-status__other-relationship-form INPUT').removeAttr('required')
    $('.c-case-status__field').not('.c-case-status__other-relationship-form .field').not('.c-case-status__cold-case-form .field').removeClass('required')
    if (report_type == 'person') {
      $('.c-case-status__business-form').hide()
      $('.c-case-status__person-form INPUT:not(:last)').attr('required', true)
      $('.c-case-status__person-form .field:not(:last-child)').addClass('required')
      $('.c-case-status__person-form').show()
      $('.c-case-status__person-form INPUT:first').focus()
    } else if (report_type == 'business') {
      $('.c-case-status__person-form').hide()
      $('.c-case-status__business-form INPUT').attr('required', true)
      $('.c-case-status__business-form .field').addClass('required')
      $('.c-case-status__business-form').show()
      $('.c-case-status__business-form INPUT:first').focus()
    }
  })

  $(document).on('change, click', '#cold_case', function () {
    var cold_case = $('#cold_case').prop('checked')
    if (cold_case) {
      $('.c-case-status__cold-case-form').show()
      $('.c-case-status__cold-case-form .field').addClass('required')
      var victim_relationship_type = $('.c-case-status__victim-relationship-type-dropdown > INPUT').val()
      if (victim_relationship_type == 'other') {
        $('.c-case-status__other-relationship-form').show()
        $('.c-case-status__other-relationship-form .field').addClass('required')
        $('.c-case-status__other-relationship-form INPUT').attr('required', true)
        $('.c-case-status__other-relationship-form INPUT:first').focus()
      }
    } else if (!cold_case) {
      $('.c-case-status__cold-case-form').hide()
      $('.c-case-status__cold-case-form .field').removeClass('required')
      $('.c-case-status__victim-relationship-type-dropdown > INPUT').val(null)
      $('.c-case-status__other-relationship-form INPUT').val(null)
      $('.c-case-status__victim-relationship-type-dropdown').dropdown('clear')
      $('.c-case-status__victim-relationship-type-dropdown .text[data-language="en"]').html(en_placeholder)
      $('.c-case-status__victim-relationship-type-dropdown .text[data-language="es"]').html(es_placeholder)
      $('.c-case-status__other-relationship-form').hide()
      $('.c-case-status__other-relationship-form .field').removeClass('required')
      $('.c-case-status__other-relationship-form INPUT').removeAttr('required')
    }
  })

  $(document).on('click', '.c-case-status__victim-relationship-type-dropdown', function () {
    $('.c-case-status__cold-case-form .c-case-status__field').removeClass('error')
    $('.c-case-status__cold-case-dropdown-error').addClass('hidden')
  })

  $(document).on('change', '.c-case-status__victim-relationship-type-dropdown', function () {
    var victim_relationship_type = $('.c-case-status__victim-relationship-type-dropdown > INPUT').val()
    if (victim_relationship_type == 'other') {
      $('.c-case-status__other-relationship-form').show()
      $('.c-case-status__other-relationship-form .field').addClass('required')
      $('.c-case-status__other-relationship-form INPUT').attr('required', true)
      $('.c-case-status__other-relationship-form INPUT:first').focus()
    } else {
      $('.c-case-status__other-relationship-form').hide()
      $('.c-case-status__other-relationship-form .field').removeClass('required')
      $('.c-case-status__other-relationship-form INPUT').removeAttr('required')
    }
  })

  /* Enables the "Subscribe" button if either a phone number or email address is entered
  and the agree field is checked. */
  $(document).on('keyup', '#phone_number, #email_address', function () {
    var agree_button = $('#agree_button').prop('checked')
    var phone_number = !_.isEmpty($("#phone_number").val())
    var email_address = !_.isEmpty($("#email_address").val())
    if (agree_button) {
      if (phone_number || email_address) {
        $('#subscribe').removeClass('disabled')
      } else if (!phone_number && !email_address) {
        $('#subscribe').addClass('disabled')
      }
    } else {
      $('#subscribe').addClass('disabled')
    }
  })

  $(document).on('change, click', '.c-case-status__agree, #agree_button', function () {
    var agree_button = $('#agree_button').prop('checked')
    var phone_number = !_.isEmpty($("#phone_number").val())
    var email_address = !_.isEmpty($("#email_address").val())
    if (!agree_button) {
      $('#agree_button').prop('checked', true)
      if (phone_number || email_address) {
        $('#subscribe').removeClass('disabled')
      }
    } else if (agree_button) {
      $('#agree_button').prop('checked', false)
      $('#subscribe').addClass('disabled')
    }
  })

  $(document).on('click', '#subscribe', function() {
    function openModal() {
      $('.c-case-status__verification-modal').modal('show')
    }
    const formObj = serializeFormData('#subscribe-form')

    // Only submit form to the API if the agree field is checked and either a phone number or email address is filled in
    if (formObj.agree_button == "on" && (formObj.phone_number || formObj.email_address)) {
      $(this).addClass('loading')
      $('.c-case-status__verification-modal-heading-error').hide();
      $('.c-case-status__verification-modal-heading').show();
      $('.c-case-status__error-message').each(function () {
        $(this).addClass('hidden')
      })
      if (formObj.phone_number) {
        $('#phone_number').attr('disabled', true)
      }
      if (formObj.email_address) {
        $('#email_address').attr('disabled', true)
      }
      $.post($('#subscribe-form').attr('action'), formObj).done(
        function (data, textStatus, jqXHR) {
          $('#subscribe').removeClass('loading')
          $('#phone_number, #email_address, #agree_button, #subscribe').attr('disabled', true)

          if (formObj.phone_number) {
            $('.c-case-status__verify-phone').removeClass('hidden')
          }
          if (formObj.email_address) {
            $('.c-case-status__verify-email').removeClass('hidden')
          }
          if (formObj.phone_number && formObj.email_address) {
            $('.c-case-status__verification-divider').removeAttr('style')
          }
          openModal()
        }
      ).fail(
        function (jqXHR) {
          $('#subscribe').removeClass('loading')
          var data = JSON.parse(jqXHR.responseText)

          $('.c-case-status__verification-modal-heading').hide();
          $('.c-case-status__verification-modal-heading-error').show();
          if (data.phone_subscribed && !data.phone_unsubscribed) {
            $('.c-case-status__phone-subscribed').removeClass('hidden')
            openModal()
          }
          if (data.phone_unsubscribed) {
            $('.c-case-status__unsubscribed-phone').removeClass('hidden')
            $('#phone_number').removeAttr('disabled')
            openModal()
          }
          if (data.email_subscribed && !data.email_unsubscribed) {
            $('.c-case-status__email-subscribed').removeClass('hidden')
            openModal()
          }
          if (data.email_unsubscribed) {
            $('.c-case-status__unsubscribed-email').removeClass('hidden')
            $('#email_address').removeAttr('disabled')
            openModal()
          }
          if (formObj.phone_number && formObj.email_address) {
            if (!data.phone_invalid && !data.email_invalid) {
              $('.c-case-status__verification-divider').removeAttr('style')
            }
          }
          if (data.phone_invalid) {
            $('.c-case-status__phone-field').addClass('error')
            $('.c-case-status__phone-field > .c-case-status__error').removeClass('hidden')
            $('#phone_number').removeAttr('disabled')
          }
          if (data.email_invalid) {
            $('.c-case-status__email-field').addClass('error')
            $('.c-case-status__email-field > .c-case-status__error').removeClass('hidden')
            $('#email_address').removeAttr('disabled')
          }
          if (formObj.email_address && !data.email_invalid && !data.email_unsubscribed && !data.email_subscribed) {
            $('.c-case-status__verify-email').removeClass('hidden')
          }
          if (formObj.phone_number && !data.phone_invalid && !data.phone_unsubscribed && !data.phone_subscribed) {
            $('.c-case-status__verify-phone').removeClass('hidden')
          }
        }
      )
    }
  })

  // Handle "Backspace" in the verification code modal
  $(document).on('keydown', '.c-case-status__verification', function (event) {
    if (event.key == 'Backspace') {
      if ((this == $('.c-case-status__verification')[5]) && $(this).val() == '') {
        $(this).parent().prev('div').find('input').focus()
      } else if ((this !== $('.c-case-status__verification')[5])) {
        $(this).parent().prev('div').find('input').focus()
      }
    }
  })

  // Clear the verification code input field on focus
  $(document).on('focus', '.c-case-status__verification', function () {
    $(this).val('')
  })

  $(document).on('keyup', '.c-case-status__verification', function (event) {
    if (event.key !== 'Backspace') {
      $(this).parent().next('div').find('input').focus()
    }
    if ($(this).parent().next('div').find('input').length === 0 && $(this).val() !== '') {
      $('.c-case-status__verified-success, .c-case-status__verified-failed').addClass('hidden')
      let verificationCode = ''
      $('.c-case-status__verification').each(function () {
        verificationCode += $(this).val()
      })
      const formObj = serializeFormData('#verify-phone-form')
      formObj.verification_code = verificationCode
      formObj.phone_number = $("#phone_number").val()
      $.post($('#verify-phone-form').attr('action'), formObj).done(
        function () {
          $(this).blur()
          $('.c-case-status__verified-success').removeClass('hidden')
          $('.c-case-status__verified-phone').removeAttr('style')
          $('.c-case-status__verification').each(function () {
            $(this).attr('disabled', true)
          })
        }
      ).fail(
        function () {
          $('.c-case-status__verified-failed').removeClass('hidden')
          $('.c-case-status__verification-code-invalid').removeClass('hidden')
          $('.c-case-status__verification-code-resent').addClass('hidden')
        }
      )
    }
  })

  $(document).on('click', '.c-case-status__resend-code', function () {
    const formObj = serializeFormData('#resend_code_form')
    formObj.phone_number = $("#phone_number").val()
    $.post($('#resend-code-form').attr('action'), formObj).done(
      function () {
        $('.c-case-status__verification').val('')
        $('.c-case-status__verification-code-invalid').addClass('hidden')
        $('.c-case-status__verification-code-resent').removeClass('hidden')
      }
    )
  })

  $(document).on('click', '#feedback-yes, #feedback-no', function () {
    const formObj = serializeFormData('#feedback-form')
    formObj.is_portal_useful = $(this).val()
    $.post($('#feedback-form').attr('action'), formObj).done(
      function () {
        $('.c-case-status__feedback-question').css('display', 'none')
        $('.c-case-status__feedback-thank-you').css('display', 'flex')
        $('.c-case-status__feedback-buttons').css('display', 'none')
      }
    )
  })

  // Removes error highlighting upon key up
  $('.c-case-status__field').find('input').on('focus', function (e) {
    if (e.key != 'Enter') {
      $('.c-case-status__field').removeClass('error')
      $('.c-case-status__phone-field').removeClass('error')
      $('.c-case-status__email-field').removeClass('error')
      $('.c-case-status__error').addClass('hidden')
    }
  });

  $(document).on('input', '#date_of_birth', function () {
    // Create this function to get rid of js error, but error seems to be unnecessary
    // this.setCustomValidity('Date format should be mm/dd/yyyy');
  })

  if ($('#user_agent')) {
    $('#user_agent').val(window.navigator.userAgent)
  }
})
